<!--
 * @Description: 约课模式选择页面
-->
<template>

    <div class="page flex-col justify-between" id="defaultView">
        <div class="box_2 flex-col">
            <div class="text-group_1 flex-col">
                <span class="text_2">选择约课模式</span>
                <span class="text_3">根据自己的需求优先考虑时间or教练</span>
                <span class="text_4">选择自己预约的课程</span>
            </div>
            <div class="list_1 flex-col">
                <div class="text-group_2-0 flex-col pjiaolian2" >
                    <span class="text_5-0">教练模式</span>
                    <span class="text_6-0">可以选择教练,筛选教练进行优先约课</span>
                </div>
                <div class="text-group_2-1 flex-col priqi2" >
                    <span class="text_5-1">日期模式</span>
                    <span class="text_6-1">可以通过选择时间，筛选上课时间进行优先约课</span>
                </div>
                <div class="text-group_2-2 flex-col group_list" >
                    <span class="text_5-2">小组课模式</span>
                    <span class="text_6-2">可以通过选择时间，筛选上课时间进行优先约课</span>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import {
    mapGetters
} from "vuex";

export default {
    name: "modeselectBar",

    data() {
        return {
            headImg: '',
        };
    },

    computed: {
        ...mapGetters(["project"]),
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style>
.page {
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    width: 100%;
    height: 812px;
    overflow: hidden;
}

.nav-bar_1 {
    width: 100%;
    height: 50px;
    margin-top: 36px;
}

.icon_1 {
    width: 9px;
    height: 15px;
    margin: 16px 0 0 18px;
}

.text_1 {
    width: 72px;
    height: 18px;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 18px;
    margin: 16px 0 0 124px;
}

.applet-top-bar_1 {
    width: 89px;
    height: 29px;
    margin: 9px 12px 0 51px;
}

.image_1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 44px;
}

.box_2 {
    width: 100%;
    height: 727px;
    margin-bottom: 1px;
}

.text-group_1 {
    width: 222px;
    height: 63px;
    margin: 20px auto 0 auto;
}

.text_2 {
    width: 97px;
    height: 16px;
    overflow-wrap: break-word;
    color: rgba(41, 41, 41, 1);
    font-size: 16px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 16px;
    margin-left: 57px;
}

.text_3 {
    width: 222px;
    height: 14px;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-family: PingFangSC-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
    margin-top: 11px;
}

.text_4 {
    width: 126px;
    height: 14px;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-family: PingFangSC-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
    margin: 8px 0 0 49px;
}

.list_1 {
    width: 91.45%;
    min-width: 343px;
    height: 532px;
    justify-content: space-between;
    margin: 26px auto 86px auto;
}

.text-group_2-0 {
    width: 91.45%;
    min-width: 343px;
    /*    height: 160px;*/
    /*    height: 19.70vh;
    min-height: 160px;*/
    margin-bottom: 26px;
    justify-content: flex-center;
    background: url(https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202306/23/942336-202306230942066373.png) no-repeat;
    background-size: cover;
    /*    background-size: 100% 100%;*/
    border-radius: 10px;
}

.text_5-0 {
    width: 80px;
    height: 18px;
    overflow-wrap: break-word;
    color: rgba(198, 156, 109, 1);
    font-size: 18px;
    font-family: SourceHanSansCN-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 18px;
    margin: 52px 0 0 112px;
}

.text_6-0 {
    width: 135px;
    height: 30px;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: NaN;
    text-align: left;
    line-height: 15px;
    margin: 8px 0 52px 112px;
}

.text-group_2-1 {
    width: 91.45%;
    min-width: 343px;
    /*    height: 160px;*/
    margin-bottom: 26px;
    border-radius: 10px;
    background: url(https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202306/23/942336-202306230942102257.png) no-repeat;
    /*    background-size: 100% 100%;*/
    background-size: cover;
}

.text_5-1 {
    width: 80px;
    height: 18px;
    overflow-wrap: break-word;
    color: rgba(198, 156, 109, 1);
    font-size: 18px;
    font-family: SourceHanSansCN-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 18px;
    margin: 52px 0 0 112px;
}

.text_6-1 {
    width: 135px;
    height: 30px;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: NaN;
    text-align: left;
    line-height: 15px;
    margin: 8px 0 52px 112px;
}

.text-group_2-2 {
    width: 91.45%;
    min-width: 343px;
    /*    height: 160px;*/
    margin-bottom: 26px;
    justify-content: flex-center;
    background: url(https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202306/23/942336-202306230942126344.png)no-repeat;
    background-size: cover;
    /*    background-size: 100% 100%;*/
    border-radius: 10px;
}

.text_5-2 {
    width: 80px;
    height: 18px;
    overflow-wrap: break-word;
    color: rgba(198, 156, 109, 1);
    font-size: 18px;
    font-family: SourceHanSansCN-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 18px;
    margin: 52px 0 0 112px;
}

.text_6-2 {
    width: 135px;
    height: 30px;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: NaN;
    text-align: left;
    line-height: 15px;
    margin: 8px 0 52px 112px;
}
</style>