<!--
 * @Description: 我的页面配置
-->
<template>
    <div class="wrap">
        <div style="padding-right:360px">
            <phone-ctn>
                <modeselect-bar></modeselect-bar>
            </phone-ctn>
        </div>

        <!-- 导航按钮 -->
        <config-ctn title="约课模式选择页面">

            <div class="p10">
                <!-- <label class=" f12">此配置适用于----- 约课模式选择页面</label> -->
                <label class=" f12">目前此页面直接跳转客多赞马术系统,不需要配置</label>
            </div>
        </config-ctn>

    </div>
</template>

<script>
import ModeselectBar from "@/components/modeSelectBar";
import { mapGetters } from "vuex";

export default {
    name: "modeSelectTpl",

    components: {
        ModeselectBar,
    },

    computed: {
        ...mapGetters(["project"]),
    },
    mounted() {
        console.log('====>', this.project);
        if (this.project.setting.agreement == null) {
            this.project.setting.agreement = {
                title: "协议内容"
            }
        }
    },

    methods: {
        handleChange() {
            console.log('The message is changed.');
        }

    },
};
</script>

<style lang="scss" scoped>
.nav-item {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>